<template>
    <div class="content flex">
        <div class="edit">
            <el-form :model="edit" :rules="rules" ref="ruleForm" class="tb-form" label-width="140px"
                     label-position="right">
                <el-form-item label="专题名称：" prop="theme_name">
                    <el-input v-model="edit.theme_name" placeholder="请输入专题名称"></el-input>
                </el-form-item>
                <el-form-item label="起止日期：" prop="date">
                    <el-date-picker
                            v-model="edit.date"
                            type="daterange"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                    />
                </el-form-item>
                <el-form-item label="专题简介：">
                    <el-input v-model="edit.theme_desc" type="textarea" placeholder="请输入专题简介，小程序界面不展示"></el-input>
                </el-form-item>
                <div class="link-product">
                    <p class="link-product-title">关联商品</p>
                    <span class="tips">提示：关联商品不得小于3张，否则首页展示将不完整</span>
                    <div class="link-product-btn">
                        <el-button class="tb-button" type="primary" @click="chooseProduct">
                            <i class="tb-icon-plus"/>关联商品
                        </el-button>
                    </div>
                    <el-table
                            :data="edit.product_list"
                            class="tb-table link-product-table"
                    >
                        <el-table-column prop="product_name" label="关联商品名称"/>
                        <!-- <el-table-column label="排序">
                          <el-input slot-scope="scope" v-model="scope.row.sort"></el-input>
                        </el-table-column> -->
                        <el-table-column label="操作">
                            <span slot-scope="scope" class="edit" @click="deleteItem(scope.row)">删除</span>
                        </el-table-column>
                    </el-table>
                    <span class="tips">排序号越小，排序位置越前</span>
                </div>
                <el-form-item label="添加banner：">
                    <div class="upload">
                        <Upload id="banner" src="activity" @success="onUpload">
                            <div class="logo mb-12" v-if="edit.imgurl">
                                <img :src="edit.imgurl" alt="img" width="100%">
                                <span class="exchange" @click.stop="deleteImgUrl">删除</span>
                            </div>
                            <div class="upload-box mb-12 flex-center" v-else>
                                <i class="el-icon-plus"></i>
                                <span class="text">点击上传</span>
                            </div>
                        </Upload>
                        <span class="tips">建议图片尺寸1125x837像素，且大小 &lt;=300KB，支持jpg、png格式</span>
                    </div>
                </el-form-item>
                <el-form-item label="专题状态：">
                    <el-radio-group v-model="edit.status">
                        <el-radio :label="1">已生效</el-radio>
                        <el-radio :label="0">未生效</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onConfirm">确定</el-button>
                    <el-button @click="onCancel">取消</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="demo">
            <h3 class="title">专题示例</h3>
            <img src="https://ddimage.topboom0912.com/admin_static_img/demo/thema.png" alt="demo" width="298px">
        </div>
        <el-dialog
                title="选择商品"
                width="50vw"
                :visible.sync="productDialog"
        >
            <div class="product-search flex">
                <span>搜索商品：</span>
                <el-input v-model="productname" style="width: 216px;" @change="serchGoods"></el-input>
            </div>
            <el-table
                    ref="multipleTable"
                    :data="productData"
                    class="tb-table"
                    height="48vh"
                    @selection-change="selectionChange"
                    @row-click="selectionSingleChange"
            >
                <el-table-column type="selection" width="80"/>
                <el-table-column prop="product_id" label="商品id" width="100"/>
                <el-table-column prop="product_name" label="商品名称"/>
            </el-table>
            <el-pagination
                    background
                    class="tb-pagination"
                    layout="prev, pager, next"
                    :current-page.sync="page.no + 1"
                    :page-size="page.size"
                    :total="page.total"
                    @current-change="pageChange"
            />
            <span slot="footer">
        <el-button @click="productDialog = false">取 消</el-button>
        <el-button type="primary" @click="productConfirm">确 定</el-button>
      </span>
        </el-dialog>
    </div>
</template>

<script>
    import Upload from '@/components/Upload'

    export default {
        name: 'ThemaAdd',
        components: {
            Upload
        },
        data() {
            return {
                id: null,
                detail: null,
                edit: {
                    theme_name: '',
                    date: null,
                    theme_desc: '',
                    media: {
                        w: 100,
                        h: 100,
                        url: ''
                    },
                    imgurl: '',
                    product_list: [],
                    status: 1
                },
                rules: {
                    theme_name: [{required: true, message: '请输入名称'}],
                    date: [{required: true, message: '请选择日期'}]
                },
                productname: '',
                productData: [],
                productChoose: [],
                page: {
                    no: 0,
                    size: 10,
                    total: 0
                },
                productDialog: false
            }
        },
        async mounted() {
            this.id = this.$route.query.id * 1 || null
            if (this.id) {
                this.getDetail()
            }
        },
        methods: {
            getDetail() {
                const url = 'admin/theme/detail?id=' + this.id
                this.$https.get(url).then(data => {
                    if (!data) return
                    this.edit.theme_name = data.theme_name
                    this.edit.theme_desc = data.theme_desc
                    this.edit.date = [data.start_time, data.end_time]
                    this.edit.status = data.status
                    this.edit.product_list = data.product_list
                    if (data.media) {
                        this.edit.imgurl = this.$img(data.media)
                        data.media = JSON.parse(data.media)
                    }
                    this.id = data.id
                    this.detail = data
                })
            },
            getProduct() {
                const {no, size} = this.page
                const params = {
                    page_no: no,
                    page_size: size,
                    name: this.productname
                }
                if (this.id) params.id = this.id
                const url = 'admin/theme/product/add/list'
                this.$https.get(url, {params}).then(res => {
                    if (!res) return
                    this.page.total = res.total_count
                    this.productData = res.list
                })
            },
            chooseProduct() {
                this.productname = ''
                this.page.no = 0
                this.productDialog = true
                this.getProduct()
            },
            serchGoods() {
                this.page.no = 0
                this.getProduct()
            },
            selectionChange(choose) {
                this.productChoose = choose;
            },
            selectionSingleChange(choose) {
                this.productChoose = choose
            },
            pageChange(no) {
                this.page.no = no - 1
                this.getProduct()
            },
            deleteItem(val) {
                if (this.id) {
                    const params = {
                        id: val.id
                    }
                    const url = 'admin/theme/delete/product'
                    this.$confirm('是否删除该商品?', '', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.$https.post(url, params).then(res => {
                            if (res) {
                                const idx = this.edit.product_list.findIndex(item => item.product_id === val.product_id)
                                this.edit.product_list.splice(idx, 1)
                            }
                        })
                    }).catch(() => {
                    })
                    
                } else {
                    const idx = this.edit.product_list.findIndex(item => item.product_id === val.product_id)
                    this.edit.product_list.splice(idx, 1)
                }
            },
            deleteImgUrl() {
                this.$confirm('是否删除该图片?', '', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.edit.imgurl = ""
                }).catch(() => {
                })
            },
            onUpload(url) {
                this.edit.imgurl = url
            },
            async onConfirm() {
                const rule = await new Promise(resolve => {
                    this.$refs.ruleForm.validate((valid) => {
                        resolve(valid)
                    })
                })
                if (!rule) return
                const {theme_name, date, theme_desc, media, imgurl, product_list, status} = this.edit
                if (product_list.length === 0) {
                    this.$message.error('请选择关联商品')
                    return
                }
                if (imgurl !== media.url) {
                    media.url = imgurl
                }
                const url = 'admin/theme/edit'
                let params = {
                    theme_name,
                    start_time: date[0],
                    end_time: date[1],
                    theme_desc,
                    status,
                    media: JSON.stringify(media)
                }
                if (this.id) {
                    params.id = this.id
                } else {
                    const ids = product_list.map(item => item.product_id)
                    params.product_ids = ids.join(',')
                }
                this.$https.post(url, params).then(data => {
                    if (data) {
                        this.$message.success('添加成功')
                        this.$router.push('/thema/list')
                    }
                })
            },
            onCancel() {
                this.$router.push('/thema/list')
            },
            productConfirm() {
                if (!this.id) {
                    let existsList = []
                    this.edit.product_list.forEach(item => {
                        this.productChoose.forEach(choose => {
                            if (item.product_id === choose.product_id) {
                                existsList.push(choose.product_name)
                            }
                        })
                    })
                    // const ischoose = this.edit.product_list.find(item => item.product_id === this.productChoose.product_id)
                    if (existsList.length > 0) {
                        this.$message.error('商品' + existsList.join(',') + '已存在')
                        return
                    }
                    this.productDialog = false
                    this.edit.product_list = [...this.productChoose, ...this.edit.product_list]
                } else {
                    let existsList = []
                    let productIdList = []
                    this.edit.product_list.forEach(item => {
                        this.productChoose.forEach(choose => {
                            // productIdList.push(choose.product_id)
                            if (item.product_id === choose.product_id) {
                                existsList.push(choose.product_name)
                            }
                        })
                    })
                    if (existsList.length > 0) {
                        this.$message.error('商品' + existsList.join(',') + '已存在')
                        return
                    }
                    this.productChoose.forEach(choose => {
                        productIdList.push(choose.product_id)
                    })
                    const params = {
                        theme_id: this.id,
                        product_id: productIdList.join(',')
                    }
                    const url = 'admin/theme/add/product'
                    this.$https.post(url, params).then(data => {
                        if (data) {
                            this.$message.success('添加成功')
                            this.getDetail()
                            this.productDialog = false
                        }
                    })
                }
            }
        }
    }
</script>

<style>
    .tb-form .specail-form-item .el-form-item__label {
        width: 165px !important;
    }
</style>

<style scoped lang="scss">
    .edit {
        padding: 0 32px;
        border-right: 1px solid #E8E8E8;
        box-sizing: border-box;

        .tb-form {
            width: 634px;

            .tips {
                padding-top: 8px;
                color: rgba(0, 0, 0, 0.45);
            }

            .demo-text {
                color: #1890FF;
                cursor: pointer;
            }

            .link-product {
                padding: 32px 0 32px;
                margin-bottom: 32px;
                border-top: 1px solid #E8E8E8;
                border-bottom: 1px solid #E8E8E8;
                box-sizing: border-box;

                &-title {
                    font-size: 16px;
                    font-weight: 600;
                    color: rgba(0, 0, 0, 0.85);
                }

                &-btn {
                    margin: 24px 0 16px;
                }

                &-table {
                    margin-bottom: 4px;

                    .edit {
                        padding: 0;
                        border-right: 0;
                    }
                }
            }

            .upload {
                display: flex;
                flex-direction: column;
                line-height: 1;

                &-box {
                    width: 375px;
                    height: 279px;
                    flex-direction: column;
                    border-radius: 4px;
                    border: 1px dashed rgba(0, 0, 0, 0.15);
                    cursor: pointer;

                    .text {
                        padding-top: 8px;
                        color: rgba(0, 0, 0, 0.65);
                    }
                }

                .logo {
                    position: relative;
                    width: 375px;
                    height: 279px;
                    overflow: hidden;
                    cursor: pointer;

                    .exchange {
                        position: absolute;
                        display: block;
                        width: 100%;
                        height: 28px;
                        line-height: 28px;
                        bottom: 0;
                        left: 0;
                        font-size: 12px;
                        color: #fff;
                        background-color: rgba(0, 0, 0, 0.45);
                        text-align: center;
                    }
                }
            }
        }
    }

    .demo {
        padding-left: 70px;

        .title {
            margin: 0;
            padding-bottom: 16px;
            font-size: 16px;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.85);
        }
    }

    .product-search {
        align-items: center;
        margin-bottom: 24px;
    }
</style>
